import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from '@/assets/ti.png';
var _hoisted_1 = {
  class: "ai-answer-card"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  key: 1,
  class: "collapse-mask"
};
var _hoisted_5 = {
  key: 0,
  class: "actions"
};
var _hoisted_6 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_LoadingText = _resolveComponent("LoadingText");

  var _component_q_icon = _resolveComponent("q-icon");

  var _component_el_button = _resolveComponent("el-button");

  var _component_el_input = _resolveComponent("el-input");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_form = _resolveComponent("el-form");

  var _component_el_dialog = _resolveComponent("el-dialog");

  var _directive_loading = _resolveDirective("loading");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[7] || (_cache[7] = _createStaticVNode("<div class=\"header\" data-v-c8925ef8><div class=\"ai-icon-title\" data-v-c8925ef8><img src=\"" + _imports_0 + "\" class=\"ai-icon\" alt=\"TiDB AI\" data-v-c8925ef8><span class=\"title\" data-v-c8925ef8>TiDB AI 小助手</span></div><div class=\"beta-warning\" data-v-c8925ef8> (该机器人仍在测试阶段，生成的信息可能不准确。) </div></div>", 1)), !$props.user ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createTextVNode(" 点击右上角登录按钮解锁 TiDB AI 小助手智能运维解答功能 ")], 64)) : $setup.isKeywordSearch ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["answer", {
      'is-collapsed': $setup.isCollapsed
    }])
  }, [$setup.loading ? (_openBlock(), _createBlock(_component_LoadingText, {
    key: 0,
    text: $setup.loadingText
  }, null, 8, ["text"])) : _createCommentVNode("", true), _createElementVNode("div", {
    innerHTML: $setup.md.render($setup.displayedAnswer)
  }, null, 8, _hoisted_3), $setup.isCollapsed && $setup.needCollapse ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true)], 2), $setup.needCollapse ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "collapse-button",
    onClick: _cache[0] || (_cache[0] = function () {
      return $setup.toggleCollapse && $setup.toggleCollapse.apply($setup, arguments);
    })
  }, [_createTextVNode(_toDisplayString($setup.isCollapsed ? "展开更多" : "收起内容") + " ", 1), _createVNode(_component_q_icon, {
    name: $setup.isCollapsed ? 'expand_more' : 'expand_less'
  }, null, 8, ["name"])])) : _createCommentVNode("", true)]), $setup.assistantMessageId && !$setup.loading ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_el_button, {
    type: "",
    link: "",
    onClick: $setup.likeFeedback
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: $setup.feedbackForm.type === 'like' ? 'thumb_up_alt' : 'thumb_up_off_alt'
      }, null, 8, ["name"])];
    }),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_button, {
    type: "",
    link: "",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      $setup.feedbackDialogVisible = true;
      $setup.feedbackForm.type = 'dislike';
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: $setup.feedbackForm.type === 'dislike' ? 'thumb_down_alt' : 'thumb_down_off_alt'
      }, null, 8, ["name"])];
    }),
    _: 1
  }), _createVNode(_component_el_button, {
    type: "",
    link: "",
    onClick: $setup.copyAnswer
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: "content_copy"
      })];
    }),
    _: 1
  }, 8, ["onClick"])])), [[_directive_loading, $setup.feedbackLoading]]) : _createCommentVNode("", true), _createVNode(_component_el_dialog, {
    modelValue: $setup.feedbackDialogVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $setup.feedbackDialogVisible = $event;
    }),
    title: "提交反馈",
    width: "500"
  }, {
    default: _withCtx(function () {
      return [_withDirectives((_openBlock(), _createBlock(_component_el_form, {
        model: $setup.feedbackForm
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_form_item, {
            label: "优化建议",
            "label-position": "top"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_input, {
                modelValue: $setup.feedbackForm.comment,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
                  return $setup.feedbackForm.comment = $event;
                }),
                autosize: {
                  minRows: 2
                },
                type: "textarea",
                placeholder: "",
                maxlength: "200"
              }, null, 8, ["modelValue"])];
            }),
            _: 1
          }), _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[3] || (_cache[3] = function ($event) {
              return $setup.dislikeFeedback();
            })
          }, {
            default: _withCtx(function () {
              return _cache[5] || (_cache[5] = [_createTextVNode("提交反馈")]);
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["model"])), [[_directive_loading, $setup.feedbackLoading]])];
    }),
    _: 1
  }, 8, ["modelValue"])], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[6] || (_cache[6] = [_createElementVNode("div", {
    class: "content"
  }, [_createElementVNode("div", {
    class: "answer"
  }, " 检测为关键词搜索，我现在只支持自然语言提问。如果需要我回答，请更详细地描述你的问题，以便于获得更合适的答案。 ")], -1)])))]);
}